import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2008.000000 1248.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1248.000000) scale(0.100000,-0.100000)">
          <path d="M2340 10093 c-2 -68 1 -4238 3 -4240 2 -1 822 -3 1823 -3 1577 0
1828 2 1888 15 370 79 663 391 707 752 7 52 9 275 6 586 -4 469 -5 507 -25
577 -34 124 -98 252 -159 320 -34 36 -36 52 -17 117 15 50 18 124 21 527 5
523 -1 614 -48 758 -36 109 -92 199 -185 298 -146 154 -288 235 -487 275
l-125 25 -1701 0 c-935 0 -1701 -3 -1701 -7z m3278 -1270 l2 -353 -1165 0
-1164 0 -3 351 c-2 193 -1 353 1 355 3 2 527 3 1165 2 l1161 -3 3 -352z m179
-1665 l6 -388 -1257 0 -1256 0 0 390 0 390 1251 -2 1250 -3 6 -387z"/>
          <path d="M7452 7976 c2 -1168 5 -2126 8 -2129 3 -2 802 -7 1777 -10 1900 -6
1918 -5 2043 44 30 11 69 26 86 33 76 28 174 97 264 186 107 105 145 157 189
257 69 158 66 127 66 778 0 474 -3 601 -14 645 -20 77 -104 250 -151 309 -45
57 -46 60 -23 121 16 40 18 102 21 534 3 498 -1 599 -35 716 -36 128 -139 288
-246 386 -168 153 -334 225 -567 244 -68 6 -793 10 -1768 10 l-1653 0 3 -2124z
m3296 842 l-3 -353 -1160 3 c-638 2 -1164 3 -1170 3 -6 -1 -10 122 -10 349 l0
350 1173 0 1172 0 -2 -352z m-65 -1281 l238 -2 6 -387 6 -388 -1262 0 -1261 0
0 388 c0 214 2 391 4 393 4 4 1806 1 2269 -4z"/>
          <path d="M12570 7967 l0 -2134 1493 -6 c820 -4 1826 -7 2235 -7 l742 0 0 465
0 465 -1752 2 -1753 3 -3 1673 -2 1672 -480 0 -480 0 0 -2133z"/>
          <path d="M2277 5643 c-2 -5 -4 -35 -4 -68 -1 -33 1 -49 3 -35 3 14 7 40 10 58
3 20 11 32 20 32 8 0 12 5 9 10 -7 12 -32 13 -38 3z"/>
          <path d="M11128 5623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M12383 5613 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path d="M15158 5613 c155 -2 409 -2 565 0 155 1 28 2 -283 2 -311 0 -438 -1
-282 -2z"/>
          <path d="M16518 5603 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z" />
          <path d="M16938 5603 c78 -2 206 -2 285 0 78 1 14 3 -143 3 -157 0 -221 -2
-142 -3z"/>
          <path d="M17388 5599 c15 -6 30 -5 40 1 13 7 8 9 -23 9 -35 0 -37 -1 -17 -10z" />
          <path d="M11408 5593 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path d="M12218 5593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M12168 5583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M11608 5543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M11883 5523 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path d="M3228 5154 c-3 -5 -3 -35 -1 -66 l5 -58 329 0 329 0 2 -437 c1 -241
2 -456 3 -478 l0 -40 138 -3 137 -3 0 481 0 480 335 0 335 0 0 60 0 60 -347 0
c-192 0 -553 3 -804 7 -271 4 -458 3 -461 -3z"/>
          <path d="M5140 4610 l0 -540 638 -1 c350 0 649 -2 665 -4 27 -4 27 -3 27 56
l0 59 -352 1 c-194 0 -429 3 -523 5 l-170 5 -3 189 -2 190 449 0 449 0 4 48
c3 26 1 52 -4 57 -6 6 -174 11 -453 13 l-445 3 0 170 0 169 520 0 520 0 0 60
0 60 -660 0 -660 0 0 -540z"/>
          <path d="M6810 4605 l0 -545 635 0 635 0 0 60 0 60 -495 0 -495 0 0 485 0 485
-140 0 -140 0 0 -545z"/>
          <path d="M10595 5140 c-251 -32 -486 -161 -553 -302 -37 -79 -44 -129 -40
-265 6 -161 26 -219 107 -302 31 -32 68 -65 83 -74 176 -109 315 -145 588
-154 456 -14 756 107 825 335 l6 22 -140 0 -140 0 -22 -50 c-56 -129 -207
-190 -473 -190 -211 0 -357 43 -454 135 -77 71 -95 123 -100 280 -6 188 19
260 114 336 49 39 91 60 179 90 64 22 86 24 260 24 183 0 193 -1 280 -29 75
-24 98 -37 136 -75 25 -25 52 -64 60 -86 l15 -40 138 -3 139 -3 -7 31 c-15 78
-107 180 -206 230 -57 29 -139 57 -220 76 -78 18 -469 27 -575 14z"/>
          <path d="M12585 5140 c-295 -22 -511 -130 -623 -312 -69 -111 -69 -354 0 -467
12 -20 25 -38 28 -41 3 -3 21 -24 39 -48 68 -88 230 -167 427 -208 118 -25
137 -26 342 -21 229 4 296 14 426 61 173 63 291 161 342 284 25 61 28 79 28
192 1 107 -2 135 -23 194 -20 60 -35 80 -100 146 -59 59 -95 86 -161 118 -182
89 -432 124 -725 102z m350 -131 c176 -31 296 -105 358 -223 19 -37 21 -57 22
-196 0 -154 0 -156 -30 -209 -52 -93 -149 -161 -285 -198 -83 -23 -375 -25
-460 -4 -155 39 -266 119 -319 231 -24 50 -26 66 -26 185 0 122 2 133 28 185
37 75 97 134 171 170 153 72 341 93 541 59z"/>
          <path d="M8390 4606 c0 -294 4 -537 9 -540 4 -3 305 -6 667 -6 l659 -1 3 60 3
61 -531 0 -530 0 0 190 0 190 455 0 455 0 0 60 0 60 -455 0 -455 0 0 170 0
170 520 0 520 0 0 60 0 60 -660 0 -660 0 0 -534z"/>
          <path d="M14013 4943 c4 -104 7 -349 7 -545 l0 -358 136 0 137 0 -7 363 c-3
199 -9 396 -12 437 l-6 75 21 -19 c11 -11 21 -23 21 -28 0 -8 110 -151 363
-467 67 -84 127 -160 132 -169 6 -9 35 -47 65 -85 30 -38 61 -78 69 -88 12
-17 27 -19 121 -19 101 0 109 1 123 23 8 12 23 31 33 42 39 42 104 124 104
130 0 3 46 62 103 131 56 69 121 150 143 180 23 30 55 71 72 91 60 75 158 200
179 229 12 16 24 26 27 23 3 -3 0 -72 -6 -155 -6 -82 -11 -270 -12 -419 l-1
-270 144 -3 144 -3 -6 392 c-4 216 -7 461 -7 546 l0 153 -179 0 c-177 0 -179
0 -194 -24 -9 -13 -22 -29 -29 -37 -7 -8 -29 -34 -48 -59 -19 -25 -40 -52 -47
-60 -6 -8 -39 -51 -73 -95 -33 -44 -87 -114 -118 -155 -32 -41 -96 -124 -142
-185 -47 -60 -113 -145 -147 -188 l-62 -78 -113 143 c-62 79 -133 170 -158
203 -24 33 -83 108 -130 166 -128 161 -275 350 -278 360 -2 5 -87 9 -189 9
l-186 0 6 -187z"/>
          <path d="M2284 4840 c0 -113 2 -159 3 -102 2 56 2 148 0 205 -1 56 -3 10 -3
-103z"/>
          <path d="M2278 4595 c-8 -37 -3 -45 25 -44 16 1 17 3 5 6 -10 3 -18 16 -20 36
l-3 32 -7 -30z"/>
          <path d="M17055 3561 c-6 -6 -30 -11 -55 -14 -42 -3 -41 -4 13 -5 45 -2 57 1
57 13 0 17 -3 18 -15 6z"/>
          <path d="M16258 3543 c56 -2 148 -2 205 0 56 1 10 3 -103 3 -113 0 -159 -2
-102 -3z"/>
          <path d="M1600 3442 c0 -4 5 -13 11 -19 17 -17 -15 -22 -146 -26 -76 -3 -122
-9 -150 -21 l-40 -17 27 -15 c61 -32 56 -84 -7 -84 -18 0 -38 -7 -45 -15 -7
-8 -31 -20 -54 -25 -68 -18 -82 -27 -103 -67 -11 -21 -29 -40 -39 -42 -40 -7
-46 -21 -24 -57 20 -32 20 -33 2 -43 -10 -5 -27 -26 -37 -45 -23 -43 -31 -45
-39 -11 -13 50 -26 13 -26 -71 0 -82 11 -110 24 -59 4 14 10 25 15 25 8 0 34
-42 65 -107 6 -13 18 -23 28 -23 9 0 27 -13 40 -28 17 -21 36 -30 67 -35 24
-3 50 -12 59 -20 12 -13 19 -13 48 -1 30 13 41 12 101 -5 38 -10 85 -26 105
-35 21 -9 55 -16 77 -16 22 0 52 -5 67 -10 58 -23 575 -34 720 -16 66 8 73 7
93 -13 18 -18 34 -21 101 -22 43 0 84 3 91 7 18 11 293 13 300 2 3 -5 80 -10
170 -12 195 -4 279 -14 279 -32 0 -7 -6 -15 -12 -17 -7 -3 16 -5 52 -5 36 1
53 3 38 5 -14 2 -29 10 -31 18 -7 18 55 23 259 20 170 -3 200 -8 169 -32 -16
-11 6 -13 146 -11 103 1 130 3 72 5 -51 2 -93 8 -93 13 0 6 37 11 83 11 45 1
147 4 227 6 80 3 163 6 185 6 154 5 282 -11 237 -30 -33 -13 -4 -23 69 -23 70
0 80 2 69 15 -6 8 -9 18 -6 23 6 11 770 16 823 6 38 -7 43 -21 11 -34 -16 -6
17 -8 97 -8 66 1 98 3 70 5 -27 2 -60 6 -73 8 -41 10 -12 25 46 25 31 0 95 7
142 15 66 12 98 13 146 4 64 -11 152 -9 374 11 100 9 152 9 202 1 110 -18 218
-14 253 9 34 23 93 26 159 8 36 -10 49 -9 78 5 45 21 198 13 288 -16 58 -18
86 -20 265 -18 110 1 223 7 250 13 60 13 63 13 195 -3 153 -18 219 -17 259 3
31 16 38 17 78 3 39 -13 48 -13 80 0 49 21 126 19 171 -4 45 -23 125 -16 160
14 19 17 40 20 130 22 59 1 127 3 152 4 70 4 160 -14 160 -32 0 -14 33 -11 68
7 20 9 67 17 124 19 55 2 108 10 130 19 32 14 41 14 78 1 30 -11 53 -12 81 -6
27 6 50 5 77 -4 29 -10 58 -11 122 -4 47 5 105 10 130 10 25 0 81 8 125 19 90
22 263 23 302 2 17 -9 34 -10 70 -2 35 8 59 8 90 0 37 -11 44 -10 73 11 39 30
140 37 285 20 88 -10 107 -10 155 5 65 21 195 23 293 7 62 -11 73 -10 125 10
72 26 175 28 232 2 31 -14 47 -16 78 -8 21 6 95 8 164 5 96 -3 136 -1 174 11
69 22 133 28 176 18 20 -5 73 -5 120 0 46 4 328 11 628 15 300 3 548 8 551 10
4 2 485 3 1070 2 585 0 1093 1 1129 3 252 16 1322 -7 1430 -30 29 -6 59 -6 85
0 54 13 95 30 95 38 0 4 6 16 14 26 11 16 17 17 29 7 12 -10 16 -8 21 13 8 33
1 45 -28 45 -12 0 -32 10 -43 23 -28 32 -70 124 -85 186 -9 42 -18 56 -37 64
-15 6 -40 24 -58 40 -17 16 -64 40 -105 54 -40 14 -78 29 -84 34 -6 5 -46 14
-89 20 -42 6 -89 15 -104 20 -14 6 -89 15 -166 19 -141 10 -435 32 -620 49
-55 5 -111 7 -125 5 -14 -2 -82 -7 -151 -10 -107 -6 -134 -4 -175 11 -39 14
-85 19 -228 20 -98 2 -181 7 -185 12 -8 11 -681 11 -681 -1 0 -4 -48 -5 -107
-1 -60 4 -238 8 -398 10 -429 4 -825 17 -825 27 0 5 7 11 15 14 34 13 -1 24
-79 24 -89 0 -106 -6 -78 -26 9 -7 14 -16 10 -20 -8 -7 -524 -6 -1048 1 -140
2 -284 4 -320 3 -36 0 -114 1 -175 3 -153 5 -372 4 -485 -1 -52 -3 -140 -4
-195 -2 -115 4 -692 -6 -999 -17 -117 -4 -229 -4 -255 1 -56 11 -117 11 -996
4 -500 -4 -719 -9 -765 -18 -77 -15 -260 -14 -289 1 -32 17 -380 14 -411 -4
-31 -18 -142 -19 -190 -2 -22 8 -54 10 -85 5 -76 -10 -201 -9 -228 1 -14 6
-85 11 -159 13 -89 1 -142 7 -161 16 -24 12 -88 12 -425 2 -218 -7 -431 -15
-472 -19 -41 -4 -94 -4 -117 1 -23 4 -61 4 -84 0 -24 -4 -84 -3 -133 1 -50 5
-125 8 -166 6 -149 -5 -390 -4 -605 3 -146 5 -234 4 -265 -4 -27 -6 -117 -9
-225 -7 -254 5 -329 3 -354 -10 -18 -10 -39 -8 -109 9 -55 14 -102 20 -129 16
-22 -3 -72 1 -110 9 -45 10 -130 15 -255 15 -193 0 -229 8 -166 35 29 13 -43
14 -624 15 -362 0 -658 -4 -658 -8z m636 -21 c14 -8 -36 -10 -191 -9 -132 1
-182 3 -133 7 42 3 80 9 83 13 11 11 220 2 241 -11z"/>
          <path d="M10895 3410 c28 -12 147 -12 165 0 10 7 -17 10 -85 9 -65 0 -93 -3
-80 -9z"/>
          <path d="M11245 3410 c8 -12 391 -12 420 0 12 5 -69 9 -203 9 -146 1 -221 -2
-217 -9z"/>
          <path d="M11815 3410 c3 -5 44 -10 90 -10 46 0 87 5 90 10 4 6 -28 10 -90 10
-62 0 -94 -4 -90 -10z"/>
          <path d="M12060 3410 c20 -13 57 -13 65 0 4 6 -11 10 -37 10 -31 0 -39 -3 -28
-10z"/>
          <path d="M12400 3410 c0 -16 91 -16 120 1 10 5 -9 9 -52 9 -39 0 -68 -4 -68
-10z"/>
          <path d="M4295 2469 c-16 -6 -14 -8 13 -8 33 -1 41 4 20 12 -7 2 -22 1 -33 -4z" />
          <path d="M5008 2453 c28 -2 76 -2 105 0 28 2 5 3 -53 3 -58 0 -81 -1 -52 -3z" />
          <path d="M6683 2363 c-194 -82 -363 -465 -363 -823 0 -145 30 -244 93 -312 84
-90 272 -114 404 -52 69 33 155 115 174 167 9 27 19 37 35 37 11 0 49 3 83 6
58 7 61 6 61 -15 0 -35 47 -118 90 -160 73 -71 235 -95 354 -52 89 32 158 90
192 161 l29 60 60 0 c33 0 70 3 83 6 17 5 22 2 22 -12 1 -34 46 -125 81 -161
52 -56 109 -77 209 -78 58 0 102 6 140 19 54 19 169 86 202 119 15 16 20 13
75 -41 67 -67 120 -90 228 -99 95 -7 175 10 269 57 l79 39 31 -24 c72 -55 130
-69 281 -70 121 0 148 3 204 23 67 24 114 46 168 78 l32 19 35 -41 c21 -24 57
-50 87 -63 45 -20 66 -22 153 -19 98 3 106 5 187 47 l85 43 48 -31 c83 -52
137 -65 276 -64 131 1 188 14 312 73 l47 22 43 -30 c80 -54 131 -67 263 -66
127 1 182 13 298 69 l50 25 31 -24 c80 -60 113 -70 236 -70 111 0 117 1 180
33 72 38 138 102 165 162 19 41 18 41 122 43 26 1 26 0 30 -71 6 -126 40 -173
124 -173 64 0 91 24 125 113 70 186 176 329 216 289 6 -6 4 -42 -6 -101 -29
-165 -16 -264 38 -293 25 -14 108 -13 134 1 10 5 35 52 56 105 58 147 156 284
206 289 24 2 25 0 22 -48 -2 -27 -10 -88 -18 -134 -28 -168 -16 -215 59 -226
52 -8 123 9 135 33 5 9 12 61 16 115 3 54 18 154 31 222 31 156 32 188 3 215
-29 27 -57 33 -121 28 -103 -9 -246 -107 -317 -218 -18 -28 -35 -49 -38 -46
-2 3 5 44 17 91 24 91 22 116 -12 153 -16 17 -31 22 -73 22 -53 0 -153 -30
-177 -54 -6 -6 -29 -25 -50 -40 -39 -30 -122 -134 -140 -176 -10 -24 -11 -24
-14 -4 -2 12 12 71 32 131 19 61 32 118 29 127 -8 22 -113 22 -160 0 -36 -17
-77 -89 -92 -161 -9 -41 -25 -49 -107 -52 -40 -1 -41 0 -53 43 -7 28 -29 63
-61 98 -43 47 -61 59 -121 78 -60 19 -84 22 -153 17 -142 -10 -237 -62 -311
-170 -36 -52 -40 -63 -44 -139 -4 -70 -8 -85 -26 -98 -62 -44 -147 -70 -230
-70 -74 0 -122 22 -154 70 -21 30 -23 44 -19 95 8 109 68 185 144 185 51 0 57
-11 42 -70 -12 -48 -12 -53 6 -66 10 -8 38 -14 61 -14 77 0 134 77 118 160 -9
44 -64 97 -116 111 -69 18 -194 6 -266 -26 -122 -55 -212 -185 -212 -308 l0
-52 -78 -36 c-104 -49 -191 -65 -268 -49 -62 13 -118 46 -93 55 8 2 50 14 93
25 148 39 221 86 257 165 23 49 24 84 4 131 -25 61 -64 87 -151 104 -108 21
-245 -14 -340 -87 -17 -13 -49 -51 -70 -85 -34 -56 -38 -70 -42 -144 l-4 -81
-54 -33 c-41 -25 -69 -34 -119 -37 -63 -6 -65 -5 -100 30 -42 42 -44 65 -9 98
44 40 184 219 240 304 109 168 143 263 151 415 5 100 4 109 -20 157 -34 67
-77 93 -148 93 -206 -2 -364 -217 -450 -613 -17 -76 -23 -137 -23 -239 l-1
-136 -50 -30 c-122 -72 -212 -98 -312 -89 -66 7 -123 32 -123 54 0 7 11 13 25
13 39 0 196 54 242 83 65 41 98 98 98 168 0 49 -4 63 -31 95 -91 112 -355 93
-483 -35 -70 -70 -94 -123 -99 -217 l-4 -82 -65 -33 c-117 -60 -236 -41 -283
44 -20 35 -25 59 -28 153 l-4 111 163 5 c131 4 172 8 202 23 44 21 61 47 53
81 -6 22 -12 24 -74 26 -37 2 -89 2 -115 1 -27 -2 -47 1 -45 6 2 4 25 27 53
51 92 79 136 149 153 240 33 179 -82 273 -255 208 -129 -48 -253 -230 -313
-455 l-11 -43 -65 0 -65 0 15 28 c26 48 63 135 87 202 30 86 33 227 5 280 -44
83 -107 118 -193 105 -70 -10 -114 -33 -171 -88 -133 -129 -231 -376 -263
-664 l-12 -103 -67 0 -67 0 -7 38 c-14 85 -123 178 -221 190 l-45 5 59 63
c130 138 164 214 165 360 1 94 -1 103 -26 136 -41 53 -88 72 -168 65 -102 -9
-188 -68 -270 -188 -88 -128 -180 -445 -180 -619 0 -43 -12 -50 -87 -50 -32 0
-43 4 -43 15 0 32 -50 114 -91 148 -42 36 -113 67 -156 67 -13 0 -23 3 -23 6
0 3 30 40 66 82 115 134 155 225 155 353 0 60 -4 77 -25 110 -38 57 -67 72
-149 76 -53 2 -83 -2 -114 -14z m107 -132 c28 -53 -1 -190 -63 -296 -45 -78
-142 -222 -164 -245 -13 -13 -14 -8 -8 40 11 91 55 279 83 356 36 97 84 164
116 164 15 0 30 -8 36 -19z m836 -21 c23 -70 -5 -164 -95 -324 -31 -54 -144
-216 -148 -212 -5 4 22 167 41 246 32 137 84 267 124 313 26 30 65 18 78 -23z
m831 10 c39 -77 -12 -277 -110 -440 -22 -36 -45 -75 -51 -86 -27 -49 -82 -124
-92 -124 -21 0 12 209 63 400 58 218 147 335 190 250z m1954 4 c19 -23 16
-143 -6 -224 -24 -92 -98 -240 -173 -347 -36 -51 -69 -93 -73 -93 -17 0 16
225 52 360 27 102 86 248 116 288 27 35 62 42 84 16z m-1361 -100 c27 -81 -25
-201 -127 -294 -57 -52 -78 -62 -69 -32 3 9 10 37 15 62 27 123 72 226 121
278 30 31 47 28 60 -14z m-576 -469 c5 -21 13 -25 44 -26 20 -1 51 -2 67 -3
l30 -1 0 -98 c1 -87 -1 -99 -20 -111 -11 -8 -36 -29 -55 -46 -54 -48 -144 -91
-199 -93 -60 -3 -93 15 -115 59 -20 43 -20 39 3 61 33 32 190 224 208 254 21
36 29 36 37 4z m-1764 -75 c0 -61 17 -96 64 -138 42 -36 42 -40 16 -91 -23
-45 -70 -71 -130 -71 -36 0 -48 6 -81 39 -40 40 -44 55 -39 151 3 55 39 110
89 137 69 36 81 32 81 -27z m827 23 c-10 -52 13 -112 61 -158 38 -37 44 -47
38 -71 -4 -15 -21 -40 -37 -55 -61 -57 -139 -56 -194 1 -23 24 -30 41 -33 89
-7 99 28 168 102 202 58 26 69 25 63 -8z m2084 7 c27 -15 25 -68 -5 -102 -27
-33 -94 -64 -155 -74 l-44 -7 7 42 c8 50 49 117 85 136 31 17 85 19 112 5z
m1271 -7 c26 -23 23 -75 -5 -101 -25 -24 -116 -66 -164 -77 -28 -5 -33 -4 -33
12 0 83 80 183 147 183 20 0 45 -7 55 -17z m1318 -42 c0 -58 16 -91 67 -138
l45 -42 -19 -37 c-28 -58 -64 -79 -134 -79 -53 0 -62 3 -91 33 -32 31 -33 35
-33 116 0 76 3 86 29 120 30 39 74 65 114 66 19 0 22 -5 22 -39z"/>
          <path d="M2432 1771 c-60 -15 -64 -29 -58 -191 9 -254 33 -358 91 -396 31 -20
91 -28 149 -20 61 10 131 77 180 175 20 39 40 69 45 67 5 -3 11 -37 13 -75 9
-119 59 -176 153 -175 97 2 174 54 305 210 l35 41 65 -9 c36 -5 100 -6 142 -3
l77 6 6 -48 c14 -113 41 -163 102 -188 109 -46 227 20 303 169 19 37 38 70 41
72 3 2 9 -29 13 -69 12 -132 56 -182 159 -182 38 1 76 8 98 18 43 21 145 114
193 177 l35 44 149 1 150 0 6 -57 c15 -147 94 -210 226 -178 68 16 143 90 185
183 17 37 33 67 36 67 4 0 10 -33 14 -72 13 -140 62 -195 166 -186 73 6 117
29 182 96 81 83 220 281 289 412 30 58 31 61 13 80 -15 16 -31 20 -83 20 -86
0 -109 -16 -151 -102 -36 -73 -149 -248 -201 -308 l-29 -35 -1 206 c0 195 -1
207 -21 225 -27 24 -114 25 -151 1 -14 -10 -39 -44 -54 -75 -15 -31 -34 -70
-42 -87 -39 -80 -156 -265 -163 -258 -4 4 -13 96 -19 203 -12 186 -14 196 -37
218 -26 24 -66 28 -124 12 -47 -13 -59 -42 -59 -140 l0 -78 -72 -9 c-40 -5
-76 -8 -80 -6 -4 2 2 24 12 49 29 66 26 117 -7 154 -66 73 -200 41 -267 -64
-22 -35 -26 -127 -6 -151 14 -17 17 -12 -65 -110 -89 -106 -82 -100 -89 -68
-3 15 -8 109 -12 208 -8 218 -11 225 -88 225 -73 0 -92 -18 -160 -152 -63
-125 -155 -286 -166 -293 -11 -7 -18 42 -25 165 -11 204 -16 250 -32 265 -23
24 -124 20 -157 -6 -25 -19 -26 -25 -26 -109 0 -101 6 -94 -97 -105 -69 -8
-75 -2 -48 45 8 14 14 50 15 79 0 46 -4 59 -27 83 -23 25 -34 28 -86 28 -70 0
-118 -23 -159 -77 -35 -46 -44 -94 -29 -155 l13 -48 -73 -85 c-41 -46 -77 -81
-80 -77 -9 9 -29 262 -29 359 0 82 -1 83 -89 83 -68 0 -82 -11 -130 -110 -57
-114 -72 -143 -128 -237 -30 -51 -58 -93 -62 -93 -8 0 -31 267 -31 356 0 27
-6 58 -12 67 -15 20 -89 30 -136 18z"/>
          <path d="M14598 1715 c-190 -52 -313 -242 -264 -406 57 -195 364 -260 637
-136 l74 33 43 -33 c108 -82 315 -89 437 -13 45 28 101 92 127 145 l21 43 67
4 67 3 7 -89 c7 -102 21 -131 73 -152 82 -35 145 6 177 114 35 120 152 292
198 292 19 0 20 -5 15 -57 -2 -32 -10 -101 -17 -154 -12 -87 -11 -99 5 -133
26 -54 68 -79 124 -71 59 7 74 23 112 114 55 133 89 191 143 247 92 95 107 70
65 -118 -21 -97 -25 -179 -9 -208 24 -44 158 -51 195 -10 13 14 19 48 26 132
4 62 17 157 29 212 28 135 27 201 -2 220 -104 68 -302 -17 -419 -179 -70 -97
-71 -97 -43 -11 31 98 32 166 3 192 -16 15 -36 19 -88 19 -56 0 -78 -5 -133
-33 -36 -19 -90 -58 -119 -87 -49 -50 -62 -67 -105 -148 -9 -16 -17 -27 -20
-25 -4 4 3 33 45 190 29 106 25 112 -66 106 -94 -6 -120 -29 -154 -131 -13
-40 -30 -78 -38 -84 -8 -7 -40 -13 -71 -13 l-56 0 -13 48 c-16 62 -108 154
-172 174 -67 20 -223 15 -285 -9 -147 -58 -234 -178 -234 -327 0 -42 -2 -45
-47 -71 -69 -39 -186 -67 -245 -59 -102 14 -142 70 -133 184 9 101 66 170 140
170 53 0 59 -11 44 -68 -17 -67 -5 -82 64 -82 44 0 53 4 83 36 29 30 34 43 34
83 0 59 -29 106 -85 138 -32 18 -55 23 -115 22 -41 0 -96 -7 -122 -14z m772
-173 c0 -38 6 -58 30 -90 16 -23 41 -49 55 -58 31 -20 31 -38 3 -80 -41 -59
-140 -85 -193 -50 -44 29 -60 65 -60 136 0 50 6 75 25 108 27 48 72 80 113 81
26 1 27 -1 27 -47z"/>
          <path d="M5974 1361 c-62 -38 -81 -99 -50 -160 40 -76 207 -79 282 -4 19 19
24 34 24 71 -1 82 -42 112 -152 112 -54 0 -81 -5 -104 -19z"/>
          <path d="M13973 1335 c-101 -43 -90 -186 16 -215 82 -23 167 -4 211 47 53 59
35 139 -36 169 -43 18 -149 17 -191 -1z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
